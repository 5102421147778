.datepicker {
  width: 100%;
}
.myContainer {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  flex: 1 1 0px;
}

.myDatePicker {
  width: 100%;
  height: 48px;
  padding: 8px 48px 8px 16px;
  font-size: 100%;
}
.textnote-label {
  color: red !important;
  font-family: "tlightregular", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 1rem;
  letter-spacing: -0.8px;
  line-height: 1.5;
  word-wrap: break-word;
}

#tooltipbar{
  display: inline-flex;
  margin-left: 12px;
}